/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'telephone-inbound': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15.854.146a.5.5 0 010 .708L11.707 5H14.5a.5.5 0 010 1h-4a.5.5 0 01-.5-.5v-4a.5.5 0 011 0v2.793L15.146.146a.5.5 0 01.708 0m-12.2 1.182a.678.678 0 00-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 004.168 6.608 17.6 17.6 0 006.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 00-.063-1.015l-2.307-1.794a.68.68 0 00-.58-.122l-2.19.547a1.75 1.75 0 01-1.657-.459L5.482 8.062a1.75 1.75 0 01-.46-1.657l.548-2.19a.68.68 0 00-.122-.58zM1.884.511a1.745 1.745 0 012.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 00.178.643l2.457 2.457a.68.68 0 00.644.178l2.189-.547a1.75 1.75 0 011.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 01-7.01-4.42 18.6 18.6 0 01-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>',
    },
});
